import { computed, observable, action } from 'mobx'
export default class MenuMobx {

	@observable menus = []

	constructor() {
        this.onInitial();
    }

    @action
    onInitial() {
        try {
			
        } catch (error) {

        }
	}

	@computed
    get getMenus() {
        return this.menus
	}
	
}

// export default new UserMobx();