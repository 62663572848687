import { Route, route, Router } from 'preact-router'
import RootRoute from './routes/Root'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import NotfoundScene from './screens/NotfoundScene'
import AccountRoute from './routes/AccountRoute'
import { useStore } from './helpers/use-store'
import './style/index.scss'
import ReactGA from "react-ga";

const App = () => {
	function handleRouter(e) {


		//////////F12 disable code////////////////////////
		// document.onkeydown = function (event) {
		// 	event = (event || window.event);
		// 	if (event.keyCode == 123 || (event.ctrlKey && event.shiftKey && event.keyCode==73)) {
		// 		return false;
		// 	}
		// }
		// document.oncontextmenu = function (e) {
		// 	if (e.button == 2) {
		// 		e.preventDefault();
		// 		return false;
		// 	}
		// }

		/** google analytic */
		// ReactGA.initialize('UA-194725587-2');
		// ReactGA.pageview(window.location.pathname + window.location.search);

		const { user } = useStore();
		const allowed = user.onCheckLogin
		if (!allowed) {
			if (e.url.indexOf('account') >= 0) {
				return route(e.url)
			}
			return route('/account/login')
		}
		if (e.url.indexOf('/account/') >= 0) return route('/')
	}

	return (
		<div>
			<Router onChange={e => handleRouter(e)}>
				<AccountRoute path='/account/:rest*' />
				<AuthenticatedRoute
					path='/:rest*'
					login='/'
					route={RootRoute}
				/>
				<Route path='*' default component={NotfoundScene} />
			</Router>
		</div>

	)
}

export default App
